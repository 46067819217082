module.exports = {
  home: {
    en: '/',
    fr: '/fr/',
  },
  solutions: {
    en: '/solutions/',
    fr: '/fr/solutions/',
  },
  discovery: {
    en: '/modules/discovery/',
    fr: '/fr/modules/decouverte/',
  },
  develop: {
    en: '/modules/develop-and-diagnose/',
    fr: '/fr/modules/developper-et-diagnostiquer/',
  },
  decision: {
    en: '/modules/decision/',
    fr: '/fr/modules/decision/',
  },
  why: {
    en: '/why/',
    fr: '/fr/pourquoi/',
  },
  about: {
    en: '/about/',
    fr: '/fr/a-propos/',
  },
  leadership: {
    en: '/leadership/',
    fr: '/fr/leadership/',
  },
  jobs: {
    en: '/careers/',
    fr: '/fr/carrieres/',
  },
  'blog-posts': {
    en: '/blog/',
    fr: '/fr/blogue/',
  },
  'tech-posts': {
    en: '/tech-insights/',
    fr: '/fr/tech-insights/',
  },
  'news-posts': {
    en: '/news/',
    fr: '/fr/nouvelles/',
  },
  contact: {
    en: '/contact/',
    fr: '/fr/contact/',
  },
  contactThanks: {
    en: '/contact/thanks/',
    fr: '/fr/contact/merci/',
  },
  privacy: {
    en: '/privacy/',
    fr: '/fr/confidentialite/',
  },
  markets: { en: '/markets/', fr: '/fr/marches/' },
  hospitals: {
    en: '/markets/hospitals/',
    fr: '/fr/marches/hopitals/',
  },
  'hospitals-discovery': {
    en: '/markets/hospitals/discovery/',
    fr: '/fr/marches/hopitals/discovery/',
  },
  'hospitals-develop-diagnose': {
    en: '/markets/hospitals/develop-diagnose/',
    fr: '/fr/marches/hopitals/develop-diagnose/',
  },
  'hospitals-decision': {
    en: '/markets/hospitals/decision/',
    fr: '/fr/marches/hopitals/decision/',
  },
  'hospitals-communities': {
    en: '/markets/hospitals/communities/',
    fr: '/fr/marches/hopitals/communities/',
  },
  404: {
    en: '/',
    fr: '/fr/',
  },
};
