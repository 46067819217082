/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment } from 'react';
import { Link } from 'gatsby';
import { get } from 'lodash';

import paths from '../utils/paths';
import { linkStyles } from '../utils/styles';

import useCurrentLanguage from '../hooks/use-current-language';

function getTranslatedPath(page, lang) {
  return get(paths, [page, lang], lang ? `/${lang}` : '/');
}

const LanguageLink = ({ inverted, ...props }) => (
  <Link {...props} sx={linkStyles({ inverted })} />
);

const LanguageToggle = ({ page, inverted, ...props }) => {
  const lang = useCurrentLanguage();

  return (
    <div
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.08em',
      }}
    >
      {lang !== 'en' ? (
        <Fragment>
          <LanguageLink
            inverted={inverted}
            title="Continue in English"
            to={getTranslatedPath(page, 'en')}
          >
            EN
          </LanguageLink>
          <span sx={{ color: 'muted' }}>/FR</span>
        </Fragment>
      ) : (
        <Fragment>
          <span sx={{ color: 'muted' }}>EN/</span>
          <LanguageLink
            inverted={inverted}
            title="Poursuivre en français"
            to={getTranslatedPath(page, 'fr')}
          >
            FR
          </LanguageLink>
        </Fragment>
      )}
    </div>
  );
};

export default LanguageToggle;
