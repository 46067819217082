import { graphql, useStaticQuery } from 'gatsby';

import useCurrentLanguage from '../hooks/use-current-language';

export default function useSiteMetadata() {
  const lang = useCurrentLanguage();

  const { site } = useStaticQuery(graphql`
    query SITE_METADATA_QUERY {
      site {
        siteMetadata {
          title
          description {
            en
            fr
          }
          siteUrl
          social {
            facebook
            twitter
            linkedin
            medium
          }
          email
          phone
          address {
            en
            fr
          }
        }
      }
    }
  `);

  const metadata = { ...site.siteMetadata };

  metadata.description = metadata.description[lang];
  metadata.address = metadata.address[lang];

  return metadata;
}
