/** @jsx jsx */
import { jsx } from 'theme-ui';

const PageContent = (props) => (
  <main
    {...props}
    sx={{
      mt: (t) => [
        // `calc(${t.sizes.headerHeightSmall} + ${t.sizes.sectionOffsetSmall})`,
        `calc(${t.sizes.headerHeight} + ${t.sizes.sectionOffset})`,
        null,
        null,
        `calc(${t.sizes.headerHeight} + ${t.sizes.sectionOffset})`,
      ],
    }}
  />
);

export default PageContent;
