/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

import { resetLink } from '../utils/styles';

const NavLink = ({ as: Tag = Link, to, inverted, ...props }) => (
  <Tag
    to={to}
    {...(Tag === Link && { activeClassName: 'active' })}
    {...props}
    sx={{
      ...resetLink,
      color: inverted ? 'background' : 'text',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      letterSpacing: '0.08em',
      '&:hover': {
        color: inverted ? 'primary' : 'secondary',
      },
      '&.active': {
        color: inverted ? 'primary' : 'secondary',
      },
    }}
  />
);

export default NavLink;
