/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

import { linkStyles } from '../utils/styles';

const TextLink = ({ inverted, ...props }) => (
  <Link {...props} sx={linkStyles({ inverted })} />
);

export default TextLink;
