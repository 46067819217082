/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment, useCallback, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { RemoveScrollBar, zeroRightClassName } from 'react-remove-scroll-bar';
import FocusLock from 'react-focus-lock';

import paths from '../utils/paths';
import { headerNavItems } from '../utils/nav-items';
import { resetLink, iconButtonOffset } from '../utils/styles';

import useOnClickOutside from '../hooks/use-on-click-outside';
import useCurrentLanguage from '../hooks/use-current-language';

import logoSvg from '../img/genaiz-logo.svg';
import logoInvertedSvg from '../img/genaiz-logo-inverted.svg';

import HamburgerIcon from '../img/icons/hamburger.inline.svg';
import XIcon from '../img/icons/x.inline.svg';
import ArrowDownIcon from '../img/icons/arrow-down-small.inline.svg';

import Icon from '../components/Icon';

import NavLink from './NavLink';
import { Button, IconButton } from './Buttons';
import LanguageToggle from './LanguageToggle';
import SocialLinks from './SocialLinks';

const MobileNavLink = ({ to, ...props }) => (
  <Link
    to={to}
    activeClassName="active"
    {...props}
    sx={{
      ...resetLink,
      display: 'block',
      px: 7,
      py: 1,
      '&:hover': {
        color: 'mobileNav.hoverText',
        bg: 'mobileNav.hoverBg',
      },
      '&.active': {
        color: 'mobileNav.activeText',
        background: (t) =>
          `linear-gradient(45deg, ${t.colors.mobileNav.activeBg} 50%, transparent 90%)`,
      },
    }}
  />
);

const PageHeader = ({ page, inverted, slim }) => {
  const lang = useCurrentLanguage();
  const [mobileNavOpened, setMobileNavOpened] = useState(false);
  const toggleMobileNav = useCallback(() => setMobileNavOpened((s) => !s), []);

  const mobileNavRef = useRef();
  useOnClickOutside(mobileNavRef, toggleMobileNav);

  return (
    <Fragment>
      {mobileNavOpened && <RemoveScrollBar />}

      <FocusLock returnFocus disabled={!mobileNavOpened}>
        <nav
          className={zeroRightClassName}
          {...(mobileNavOpened
            ? {
                ref: mobileNavRef,
                style: {
                  visibility: 'visible',
                  transform: 'translateX(0)',
                  opacity: 1,
                },
              }
            : {
                'aria-hidden': true,
              })}
          sx={{
            variant: 'sections.mobileNav',
            display: 'flex',
            flexDirection: 'column',
            transition: 'transform 150ms ease-out, opacity 100ms ease-in-out',
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            maxWidth: '700px',
            height: '100%',
            zIndex: 'mobileNav',
            visiblity: 'hidden',
            transform: 'translateX(-100%)',
            opacity: 0,
            p: 7,
            pt: 0,
            overflowY: 'auto',
          }}
        >
          {mobileNavOpened && (
            <Fragment>
              <header
                sx={{
                  display: 'flex',
                  flex: 'none',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 'headerHeight',
                }}
              >
                <Link to={paths.home[lang]} aria-label="Homepage">
                  <img
                    src={logoSvg}
                    alt="GENAIZ"
                    sx={{ display: 'block', width: '125px' }}
                  />
                </Link>
                <IconButton
                  icon={XIcon}
                  onClick={toggleMobileNav}
                  aria-label="Close Navigation"
                />
              </header>

              <div sx={{ ml: -7, mb: 5 }}>
                {headerNavItems[lang].map((navItem) =>
                  navItem.children ? (
                    <Fragment key={navItem.path}>
                      <div
                        to={navItem.path}
                        sx={{ px: 7, py: 4, color: 'muted' }}
                      >
                        {navItem.label}
                      </div>
                      {navItem.children.map((childNavColumn) =>
                        childNavColumn.map((childNavItem) => (
                          <MobileNavLink
                            key={childNavItem.path}
                            to={childNavItem.path}
                          >
                            {childNavItem.label}
                          </MobileNavLink>
                        ))
                      )}
                    </Fragment>
                  ) : (
                    <MobileNavLink
                      key={navItem.path}
                      to={navItem.path}
                      sx={{ my: 3 }}
                    >
                      {navItem.label}
                    </MobileNavLink>
                  )
                )}
              </div>

              <div sx={{ mt: 'auto' }}>
                <LanguageToggle page={page} sx={{ mb: 4 }} />

                <SocialLinks
                  iconSize="20px"
                  sx={{
                    justifyContent: 'start',
                    ml: iconButtonOffset,
                    mb: 7,
                  }}
                />

                <Button variant="secondary" to={paths.contact[lang]}>
                  {lang === 'fr' ? 'Demandez une démo' : 'Request demo'}
                </Button>
              </div>
            </Fragment>
          )}
        </nav>
      </FocusLock>

      <header
        sx={{
          display: 'grid',
          // gridTemplateColumns: ['1fr auto', null, null, 'max-content 1fr auto'],
          gridTemplateColumns: ['175px auto', null, null, '1fr max-content'],
          gridGap: 7,
          px: 7,
          // display: 'flex',
          // position: 'relative',
          // justifyContent: 'center',
          alignItems: 'center',
          height: slim ? 'headerHeightSlim' : 'headerHeight',
          transition: 'height 0.2s ease',
          zIndex: 'header',

          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bg: 'background',
          // background: 'rgba(255,255,255,0.9)',
          // backdropFilter: 'saturate(180%) blur(20px)',
        }}
      >
        <Link to={paths.home[lang]} aria-label="Homepage">
          <img
            src={inverted ? logoInvertedSvg : logoSvg}
            alt="GENAIZ"
            sx={{
              display: 'block',
              width: slim ? '125px' : '125px',
              transition: 'width 0.2s ease',
            }}
          />
        </Link>
        <div
          sx={{
            justifySelf: 'flex-end',
            // display: 'flex',
            // alignItems: 'center',
            // position: 'absolute',
            // right: 6,
          }}
        >
          <nav
            sx={{
              display: ['none', null, null, 'grid'],
              gridGap: 7,
              '@media (min-width: 1400px)': {
                gridGap: 9,
              },
              gridAutoFlow: 'column',
              alignItems: 'center',
              // justifySelf: 'center',
              zIndex: 1,
            }}
          >
            {headerNavItems[lang].map((navItem) =>
              navItem.children ? (
                <NavLink
                  as="div"
                  key={navItem.path}
                  inverted={inverted}
                  sx={{
                    // mx: '16px',
                    // '@media (min-width: 1400px)': {
                    //   mx: '24px',
                    // },
                    cursor: 'pointer',
                    position: 'relative',
                    '&:hover > div': {
                      display: 'flex',
                      whiteSpace: 'nowrap',
                    },
                  }}
                >
                  <div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {navItem.label}
                    <Icon
                      icon={ArrowDownIcon}
                      sx={{
                        ml: 1,
                        width: 'icon',
                        height: 'icon',
                        color: 'currentColor',
                      }}
                    />
                  </div>
                  <div
                    sx={{
                      pt: 2,
                      position: 'absolute',
                      left: '-24px',
                      display: 'none',
                    }}
                  >
                    <div
                      sx={{
                        p: '14px 12px',
                        bg: 'm5',
                        borderRadius: '4px',
                        // boxShadow: '0 5px 5px -3px rgba(100, 100, 100, 0.1)',
                      }}
                    >
                      {navItem.children.map((childColumn, i) => {
                        return (
                          <div
                            key={i}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              mx: '12px',
                            }}
                          >
                            {childColumn.map((childNavItem, j) => (
                              <NavLink
                                key={childNavItem.path}
                                to={childNavItem.path}
                                inverted={inverted}
                                sx={{
                                  mx: 0,
                                  py: '1px',
                                  fontWeight: j === 0 ? 'bold' : 'normal',
                                  // textTransform: 'none',
                                }}
                              >
                                {childNavItem.label}
                              </NavLink>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </NavLink>
              ) : (
                <NavLink
                  key={navItem.path}
                  to={navItem.path}
                  inverted={inverted}
                  sx={
                    {
                      // mx: '16px',
                      // '@media (min-width: 1400px)': {
                      //   mx: '24px',
                      // },
                    }
                  }
                >
                  {navItem.label}
                </NavLink>
              )
            )}
            <div sx={{ display: ['none', null, null, 'flex'] }}>
              <LanguageToggle
                page={page}
                inverted={inverted}
                sx={{ fontSize: '12px' }}
              />
            </div>
            <Button
              to={paths.contact[lang]}
              variant="secondary"
              inverted={inverted}
              slim
              sx={
                {
                  // ml: '24px',
                  // '@media (min-width: 1400px)': {
                  //   minWidth: '256px',
                  // },
                }
              }
            >
              {lang === 'fr' ? 'Demandez une démo' : 'Request demo'}
            </Button>
          </nav>
          {!mobileNavOpened && (
            <IconButton
              icon={HamburgerIcon}
              inverted={inverted}
              onClick={toggleMobileNav}
              aria-label="Open Navigation"
              sx={{ display: [null, null, null, 'none'] }}
            />
          )}
        </div>
      </header>
    </Fragment>
  );
};

PageHeader.defaultProps = {
  inverted: false,
};

export default PageHeader;
