import paths from './paths';

export const headerNavItems = {
  en: [
    {
      label: 'Solutions',
      path: paths.solutions.en,
      children: [
        [
          { label: 'Overview', path: paths.solutions.en },
          { label: 'Discovery module', path: paths.discovery.en },
          { label: 'Develop & Diagnose module', path: paths.develop.en },
          { label: 'Decision module', path: paths.decision.en },
        ],
        // [
        //   { label: 'Markets', path: paths.markets.en },
        //   { label: 'Hospitals', path: paths.hospitals.en },
        // ],
      ],
    },
    { label: 'Why GENAIZ?', path: paths.why.en },
    { label: 'Tech Insights', path: paths['tech-posts'].en },
    { label: 'Blog', path: paths['blog-posts'].en },
    {
      label: 'Company',
      path: paths.about.en,
      children: [
        [
          { label: 'About', path: paths.about.en },
          { label: 'Leadership', path: paths.leadership.en },
          { label: 'Careers', path: paths.jobs.en },
          { label: 'News', path: paths['news-posts'].en },
        ],
      ],
    },
  ],
  fr: [
    {
      label: 'Solutions',
      path: paths.solutions.fr,
      children: [
        [
          { label: 'Survol', path: paths.solutions.fr },
          { label: 'Module Découverte', path: paths.discovery.fr },
          {
            label: 'Module Développer & Diagnostiquer',
            path: paths.develop.fr,
          },
          { label: 'Module Décision', path: paths.decision.fr },
        ],
        // [
        //   { label: 'Module Décision', path: paths.decision.fr },
        //   { label: 'Marchés', path: paths.markets.fr },
        //   { label: 'Hôpitals', path: paths.hospitals.fr },
        // ],
      ],
    },
    { label: 'Pourquoi GENAIZ?', path: paths.why.fr },
    {
      label: 'Tech Insights',
      path: paths['tech-posts'].fr,
    },
    { label: 'Blogue', path: paths['blog-posts'].fr },
    {
      label: 'Compagnie',
      path: paths.about.fr,
      children: [
        [
          { label: 'À propos', path: paths.about.fr },
          { label: 'Leadership', path: paths.leadership.fr },
          { label: 'Carrières', path: paths.jobs.fr },
          { label: 'Nouvelles', path: paths['news-posts'].fr },
        ],
      ],
    },
  ],
};

export const footerNavItems = {
  en: [
    { label: 'Solutions', path: paths.solutions.en },
    { label: 'Tech Insights', path: paths['tech-posts'].en },
    { label: 'Blog', path: paths['blog-posts'].en },
    { label: 'News', path: paths['news-posts'].en },
  ],
  fr: [
    { label: 'Solutions', path: paths.solutions.fr },
    { label: 'Tech Insights', path: paths['tech-posts'].fr },
    { label: 'Blogue', path: paths['blog-posts'].fr },
    { label: 'Nouvelles', path: paths['news-posts'].fr },
  ],
};
