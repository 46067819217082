/** @jsx jsx */
import { jsx } from 'theme-ui';

import useSiteMetadata from '../hooks/use-site-metadata';

import LinkedIn from '../img/social/linkedin.inline.svg';
import Medium from '../img/social/medium.inline.svg';
import Twitter from '../img/social/twitter.inline.svg';
import Facebook from '../img/social/facebook.inline.svg';

import { IconButton } from './Buttons';

const SocialLinks = ({ inverted, iconSize = '32px', ...props }) => {
  const { social } = useSiteMetadata();
  return (
    <div
      {...props}
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        gridGap: 3,
      }}
    >
      <IconButton
        icon={Facebook}
        inverted={inverted}
        title="GENAIZ on Facebook"
        href={social.facebook}
        target="_blank"
        rel="noopener"
        sx={{
          color: 'm3',
          '> svg': { width: iconSize, height: iconSize },
        }}
      />
      <IconButton
        icon={Twitter}
        inverted={inverted}
        title="GENAIZ on Twitter"
        href={social.twitter}
        target="_blank"
        rel="noopener"
        sx={{
          color: 'm3',
          '> svg': { width: iconSize, height: iconSize },
        }}
      />
      <IconButton
        icon={LinkedIn}
        inverted={inverted}
        title="GENAIZ on LinkedIn"
        href={social.linkedin}
        target="_blank"
        rel="noopener"
        sx={{
          color: 'm3',
          '> svg': { width: iconSize, height: iconSize },
        }}
      />
      <IconButton
        icon={Medium}
        inverted={inverted}
        title="GENAIZ on Medium"
        href={social.medium}
        target="_blank"
        rel="noopener"
        sx={{
          color: 'm3',
          '> svg': { width: iconSize, height: iconSize },
        }}
      />
    </div>
  );
};

export default SocialLinks;
