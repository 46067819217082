/** @jsx jsx */
import { jsx } from 'theme-ui';

function getContainerWidth(size) {
  switch (size) {
    case 'wider':
      return '1542px';
    case 'wide':
      return '1226px';
    default:
      return '912px';
  }
}

const baseStyles = {
  position: 'relative',
};

const Container = ({ size, sidebar, children, ...props }) =>
  size === 'full' ? (
    <div {...props} sx={baseStyles}>
      {children}
    </div>
  ) : (
    <div
      {...props}
      sx={{
        ...baseStyles,
        display: 'grid',
        gridTemplateColumns: `[left] 1fr [body] minmax(auto, ${getContainerWidth(
          size
        )}) [right] 1fr`,
        gridTemplateRows: 'auto',
        gridGap: 7,
      }}
    >
      <div sx={{ gridColumn: 'body' }}>{children}</div>
      {sidebar &&
        Object.keys(sidebar).map((side, i) => (
          <div
            key={i}
            sx={{
              gridColumn: side,
              gridRow: 1,
            }}
          >
            {sidebar[side]}
          </div>
        ))}
    </div>
  );

Container.defaultProps = {
  size: 'default',
};

export default Container;
