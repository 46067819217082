/** @jsx jsx */
import { jsx, Styled, Flex, Grid } from 'theme-ui';
import { Fragment } from 'react';
import Obfuscate from 'react-obfuscate';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import paths from '../utils/paths';
import { footerNavItems } from '../utils/nav-items';
import { linkStyles } from '../utils/styles';

import useSiteMetadata from '../hooks/use-site-metadata';
import useCurrentLanguage from '../hooks/use-current-language';

import logo from '../img/genaiz-logo.svg';
// import uni3tLogo from '../img/uni3t-logo.svg';

import Container from './Container2';
import NavLink from './NavLink';
import TextLink from './TextLink';
import SocialLinks from './SocialLinks';
// import LanguageToggle from './LanguageToggle';

const PageFooter = ({ page, showHr }) => {
  const { email, phone, address } = useSiteMetadata();
  const lang = useCurrentLanguage();

  return (
    <footer
      sx={{
        variant: 'sections.footer',
        pt: [12, null, null, 13],
        mt: 12,
      }}
    >
      {/* {showHr && (
        <Container size="wider">
          <hr
            sx={{
              height: '1px',
              backgroundColor: 'muted',
              mb: [12, null, null, 14],
            }}
          />
        </Container>
      )} */}
      <Container size="wide">
        <Grid columns={[1, 1, '1fr 2fr 1fr']} gap={[0, null, '32px']}>
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: ['center', null, 'flex-start'],
            }}
          >
            <img
              src={logo}
              alt="GENAIZ Logo"
              sx={{ width: '125px', mb: '16px' }}
            />
            {footerNavItems[lang].map((navItem) =>
              navItem.children ? (
                <Fragment key={navItem.path}>
                  <div
                    to={navItem.path}
                    sx={{
                      my: 2,
                      color: 'muted',
                      fontSize: '12px',
                      textTransform: 'uppercase',
                      letterSpacing: '0.08em',
                    }}
                  >
                    {navItem.label}
                  </div>
                  {navItem.children.map((childNavItem) => (
                    <NavLink
                      key={childNavItem.path}
                      to={childNavItem.path}
                      sx={{ mx: 0, my: 2 }}
                    >
                      {childNavItem.label}
                    </NavLink>
                  ))}
                </Fragment>
              ) : (
                <NavLink
                  key={navItem.path}
                  to={navItem.path}
                  sx={{ mx: 0, my: 2 }}
                >
                  {navItem.label}
                </NavLink>
              )
            )}
          </div>
          <div
            sx={{
              textAlign: ['center', null, 'left'],
              maxWidth: [null, null, '432px'],
              mt: [7, null, 0],
            }}
          >
            <Styled.p>
              {lang === 'fr'
                ? "GENAIZ est une solution d'IA/AM révolutionnaire qui se déploie sur l'ensemble de votre infrastructure existante. La plateforme GENAIZ permet aux clients de stimuler l'innovation par la collaboration accrue et l'amélioration du partage des connaissances, et tout cela sans complexités."
                : 'GENAIZ is a revolutionary AI/ML solution that sits on top of existing infrastructure allowing clients to foster innovation by encouraging collaboration and improving knowledge sharing, without unnecessary complexity.'}
            </Styled.p>
          </div>
          <div sx={{ mt: [7, null, 0] }}>
            <SocialLinks sx={{ justifyContent: 'space-between' }} />
          </div>
        </Grid>
      </Container>

      <Container size="wide" sx={{ mt: 7 }}>
        <div
          sx={{
            display: ['block', null, 'flex'],
            textAlign: 'center',
            ml: [null, null, '-16px'],
          }}
        >
          <Styled.p sx={{ m: '16px' }}>
            <Obfuscate
              email={email}
              headers={{
                subject: 'GENAIZ',
              }}
              onClick={() => {
                trackCustomEvent({
                  category: 'Footer Link',
                  action: 'click',
                  label: 'Email address',
                });
              }}
              sx={linkStyles()}
            />
          </Styled.p>
          <Styled.p sx={{ m: '16px' }}>
            <Obfuscate
              tel={phone}
              onClick={() => {
                trackCustomEvent({
                  category: 'Footer Link',
                  action: 'click',
                  label: 'Phone number',
                });
              }}
              sx={linkStyles()}
            />
          </Styled.p>
          <Styled.p sx={{ m: '16px' }}>{address}</Styled.p>
        </div>
      </Container>

      <Container size="wider">
        <hr sx={{ height: '1px', backgroundColor: 'muted' }} />
      </Container>

      <Container size="wide">
        <Flex sx={{ justifyContent: 'space-between', pt: '16px', pb: '32px' }}>
          <Styled.p sx={{ fontSize: '12px', textTransform: 'uppercase' }}>
            {`© UNI3T, ${new Date().getFullYear()}, ${
              lang === 'fr' ? 'Tous droits réservés' : 'All rights reserved'
            }`}
          </Styled.p>
          <Styled.p sx={{ fontSize: '12px' }}>
            <TextLink
              to={paths.privacy[lang]}
              sx={{ textTransform: 'uppercase' }}
            >
              {lang === 'fr'
                ? 'Politique de confidentialité'
                : 'Privacy Policy'}
            </TextLink>
          </Styled.p>
          {/* <Styled.p sx={{ display: 'flex' }}>
            <span
              sx={{
                fontSize: '12px',
                fontWeight: 'light',
                mr: '10px',
              }}
            >
              powered by
            </span>
            <a
              href="https://uni3t.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={uni3tLogo} alt="UNI3T" sx={{ width: '64px' }} />
            </a>
          </Styled.p> */}
        </Flex>
      </Container>
    </footer>
  );
};

export default PageFooter;
