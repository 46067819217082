/** @jsx jsx */
import { jsx } from 'theme-ui';
import { SkipNavLink as BaseSkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';

import { resetLink } from '../utils/styles';

const SkipNavLink = () => (
  <BaseSkipNavLink
    sx={{
      '&:focus': {
        ...resetLink,
        bg: 'background',
        padding: '16px',
        zIndex: 'skipLink',
        top: '32px',
        left: '32px',
      },
    }}
  />
);

export default SkipNavLink;
