/** @jsx jsx */
import { jsx } from 'theme-ui';

const DevTools = () => (
  <div
    sx={(t) => ({
      position: 'fixed',
      zIndex: 'devTools',
      fontSize: '10px',
      bg: 'background',
      pl: '2px',
      pr: '3px',
      borderBottomRightRadius: '5px',
      ...(t.breakpoints && {
        '&:before': {
          content: (t) => [
            `'A '`,
            ...t.breakpoints.map(
              (bp, i) => `'${String.fromCharCode(98 + i)} '`
            ),
          ],
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        '&:after': {
          content: (t) => [null, ...t.breakpoints.map((bp, i) => `'(≥${bp})'`)],
          opacity: 0.5,
        },
      }),
    })}
  />
);

export default DevTools;
