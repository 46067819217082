/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { SkipNavContent } from '@reach/skip-nav';

import icon from '../img/genaiz-thumbnail.png';

import useCurrentLanguage from '../hooks/use-current-language';
import paths from '../utils/paths';

import DevTools from '../components/DevTools';
import SkipNavLink from '../components/SkipNavLink';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageContent from '../components/PageContent';
import PageFooter from '../components/PageFooter';

import './all.sass';

import useSiteMetadata from '../hooks/use-site-metadata';

function checkScroll() {
  return typeof window !== 'undefined' ? window.pageYOffset > 32 : 0;
}

const Layout = ({ page, seo, invertHeader, children }) => {
  const lang = useCurrentLanguage();
  const { title, description, siteUrl } = useSiteMetadata();

  const { pathname } = useLocation();
  const normalizedPathname = pathname.replace(/\/?$/, '/');
  const canonical = `${siteUrl}${normalizedPathname}`;

  const [isScrolled, setIsScrolled] = useState(checkScroll);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(checkScroll());
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
        <html lang={lang} />

        {seo.title && <title>{seo.title}</title>}
        {seo.subtitle && <subtitle>{seo.subtitle}</subtitle>}

        <meta name="description" content={seo.description || description} />

        <link rel="canonical" href={canonical} />
        {Object.keys(paths[page]).map((l) => (
          <link
            key={l}
            rel="alternate"
            hreflang={l}
            href={`${siteUrl}${paths[page][l]}`}
          />
        ))}

        <meta property="og:url" content={canonical} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title || title} />
        <meta
          property="og:description"
          content={seo.description || description}
        />
        <meta property="og:image" content={`${siteUrl}${icon}`} />
        <meta property="og:image:alt" content="GENAIZ" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="1200" />

        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/8750050.js"
        />
      </Helmet>
      <SkipNavLink />
      {process.env.NODE_ENV === 'development' && <DevTools />}
      <Page>
        <PageHeader page={page} inverted={invertHeader} slim={isScrolled} />
        <SkipNavContent />
        <PageContent>{children}</PageContent>
        <PageFooter showHr={!invertHeader} page={page} />
      </Page>
    </Fragment>
  );
};

Layout.defaultProps = {
  seo: {},
};

export default Layout;
