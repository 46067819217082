/** @jsx jsx */
import { jsx } from 'theme-ui';

const Icon = ({ icon: Svg, inverted, ...props }) => (
  <div
    {...props}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'iconBox',
      height: 'iconBox',
      color: inverted ? 'background' : 'text',
    }}
  >
    <Svg
      aria-hidden="true"
      sx={{
        width: 'icon',
        height: 'icon',
      }}
    />
  </div>
);

export default Icon;
